import Image from "next/image";
import { Trans, useTranslation } from "next-i18next";

import useOnceActivatedIntersectionObserver from "@sellernote/_shared/src/hooks/common/useOnceActivatedIntersectionObserver";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import LinkButton from "@sellernote/_sds-v2/src/components/button/Button/LinkButton";
import ChevronRightIcon from "@sellernote/_sds-v2/src/components/svgIcons/ChevronRightIcon";

import Styled from "./index.styles";

export default function AEOSection() {
  const { t } = useTranslation(["page-main"]);

  const { isMobile } = useCheckIsMobile();

  const { target, isActivatedTarget } = useOnceActivatedIntersectionObserver({
    threshold: 0.3,
  });

  return (
    <Styled.container ref={target}>
      <Styled.content>
        <Styled.logoWrapper
          className={`${
            isMobile ? `fade-up-animation` : `fade-in-and-spring-animation`
          } ${isActivatedTarget ? "active" : ""}`}
        >
          <Image
            src={
              isMobile
                ? "/assets/images/main/aeo-m.webp"
                : "/assets/images/main/aeo.webp"
            }
            width={isMobile ? 80 : 180}
            height={isMobile ? 80 : 180}
            alt="aeo-logo"
          />
        </Styled.logoWrapper>

        <Styled.contentContainer>
          <Styled.titleAndDescription>
            <h3
              className={`fade-up-animation ${
                isActivatedTarget ? "active" : ""
              }`}
            >
              <Trans
                i18nKey="page-main:안전하고_간단한_수출입_절차___mobileBr_/_관세청이_보장합니다"
                components={{
                  mobileBr: <br className="mobile-only" />,
                }}
              />
            </h3>

            <p
              className={`fade-up-animation ${
                isActivatedTarget ? "active" : ""
              }`}
            >
              <Trans
                i18nKey="page-main:국내_디지털_포워딩_스타트업_최초_관세청이_주도하는__mobileBr_/_AEO_인증으로_신속한_수출입_절차에_안전성을_더했습니다._br_/_보안_안전이_중요한_공급망__물류_위험_관리를_쉽다와_함께_해보세요."
                components={{
                  mobileBr: <br className="mobile-only" />,
                  br: <br />,
                }}
              />
            </p>
          </Styled.titleAndDescription>

          <LinkButton
            className={`fade-up-animation ${isActivatedTarget ? "active" : ""}`}
            theme="tertiary"
            label={t("page-main:자세히_보기")}
            width="137px"
            borderType="outlined"
            iconInfo={{
              Icon: ChevronRightIcon,
              position: "right",
            }}
            size={isMobile ? "small" : "normal"}
            href="https://ship-da.com/blog/aeo-%EB%9C%BB-%ED%98%9C%ED%83%9D%EB%B6%80%ED%84%B0-%EA%B4%80%EC%84%B8%EC%B2%AD-%EC%9D%B8%EC%A6%9D-%ED%8F%AC%EC%9B%8C%EB%94%A9-%EC%97%85%EC%B2%B4%EC%9D%98-%EC%9E%A5%EC%A0%90%EC%9D%80--25395"
          />
        </Styled.contentContainer>
      </Styled.content>
    </Styled.container>
  );
}
