import React from "react";
import dynamic from "next/dynamic";
import { Trans } from "next-i18next";

import ScheduleSearchBarForDesktop from "../../../containers/searchBarForSchedule/ScheduleSearchBarForDesktop";

import Styled from "./index.styles";

const Video = dynamic(() => import("./Video"), {
  ssr: false,
});

function TitleAndCta() {
  return (
    <Styled.sectionIntroWrapper>
      <Styled.sectionIntro>
        <Styled.mainDescription>
          <h2>
            <Trans
              i18nKey="page-main:투명한_일정과_mobileBr_/__합리적인_운임으로_mobileBr_/__물류를_쉽게"
              components={{
                mobileBr: <></>,
              }}
            />
          </h2>

          <p className="desc">
            <Trans
              i18nKey="page-main:쉽다_포워딩_전문가가_직접_제공하는_mobileBr_/__디지털_포워딩_서비스를_지금_경험해보세요_"
              components={{
                mobileBr: <></>,
              }}
            />
          </p>
        </Styled.mainDescription>

        <Styled.ctaContainer>
          <ScheduleSearchBarForDesktop isLanding />
        </Styled.ctaContainer>
      </Styled.sectionIntro>

      <Video />
    </Styled.sectionIntroWrapper>
  );
}

export default TitleAndCta;
