import { useRef, useState } from "react";
import Slider from "react-slick";
import Router from "next/router";
import { useTranslation } from "next-i18next";

import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";

import LoginModal from "../../../../containers/LoginModal";
import { getURLWithQuerystringForScheduleSelect } from "../../../../utils/quote";

import { NextArrow, PrevArrow } from "../Arrows";
import Styled from "../index.styles";
import RateItem from "./RateItem";
import useGetHeroSectionRateList from "./useGetHeroSectionRateList";

function DiscountRateContent() {
  const { t } = useTranslation("page-main");

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const selectedScheduleId = useRef<number>();
  const selectedScheduleWarehouseId = useRef<number>();

  const { isMobile } = useCheckIsMobile();

  const sliderSettings = (() => {
    {
      return {
        dots: false,
        infinite: false,
        slidesToShow: isMobile ? 2 : 4,
        slidesToScroll: 1,
        autoplay: false,
        easing: "ease-in",
        rows: 1,
        arrows: !isMobile,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
      };
    }
  })();

  const rateList = useGetHeroSectionRateList();

  const handleLoginModalOpen =
    ({
      scheduleId,
      warehouseId,
    }: {
      scheduleId: number;
      warehouseId: number | undefined;
    }) =>
    () => {
      setIsLoginModalOpen(true);
      selectedScheduleId.current = scheduleId;
      selectedScheduleWarehouseId.current = warehouseId;
    };

  const moveToOrderPage = () => {
    Router.push(
      getURLWithQuerystringForScheduleSelect({
        scheduleId: selectedScheduleId.current,
        warehouseId: selectedScheduleWarehouseId.current,
      })
    );
  };

  if (!rateList) {
    return null;
  }

  return (
    <>
      <Styled.discountRateContent>
        <Styled.rateList>
          <Slider {...sliderSettings}>
            {rateList.map((v) => {
              return (
                <RateItem
                  data={v}
                  key={v.scheduleId}
                  onLoginModalOpen={handleLoginModalOpen({
                    scheduleId: v.scheduleId,
                    warehouseId: v.warehouseId,
                  })}
                />
              );
            })}

            {/* 모바일에서 마지막 섹션 옆의 여백을 위해, 빈 슬라이드 추가 */}
            {isMobile && <div style={{ width: "290px" }}></div>}
          </Slider>
        </Styled.rateList>

        <p className="desc">
          *
          {t(
            "page-main:위_운임은_확정운임이_아니며__물류_시황이나_구체적인_운송계획에_따라_변동될_수_있습니다."
          )}
        </p>
      </Styled.discountRateContent>

      <LoginModal
        active={isLoginModalOpen}
        setIsVisibleModal={setIsLoginModalOpen}
        afterLoginCallback={moveToOrderPage}
        backToAfterRegister={getURLWithQuerystringForScheduleSelect({
          scheduleId: selectedScheduleId.current,
          warehouseId: selectedScheduleWarehouseId.current,
        })}
      />
    </>
  );
}

export default DiscountRateContent;
