import styled from "styled-components";

const registrationWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;

  transition: transform 0.3s ease-in-out;
  transform: translateY(100%);

  &.is-visible {
    transform: translateY(0);
  }
`;

export default {
  registrationWrapper,
};
