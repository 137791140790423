import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import SCHEDULE_QUERY from "@sellernote/_shared/src/queries/forwarding/SCHEDULE_QUERY";
import { HeroSectionSchedule } from "@sellernote/_shared/src/types/forwarding/schedule";

export type HeroSectionScheduleListWithCountry = HeroSectionSchedule & {
  polFlagUrl?: string | null;
  podFlagUrl?: string | null;
};

export default function useGetHeroSectionRateList():
  | HeroSectionScheduleListWithCountry[]
  | undefined {
  const { data: heroSectionScheduleList } =
    SCHEDULE_QUERY.useGetHeroSectionScheduleList();

  const { data: allCountryList } = COMMON_QUERY.useGetUserCountryList({
    isForOceanTicket: false,
  });

  // heroSectionScheduleList 에 country flag url 정보 추가
  const heroSectionScheduleListWithCountry = heroSectionScheduleList?.map(
    (schedule) => {
      const polCountry = allCountryList?.find(
        (country) => country.name === schedule.polCountry
      );
      const podCountry = allCountryList?.find(
        (country) => country.name === schedule.podCountry
      );

      return {
        ...schedule,
        polFlagUrl: polCountry?.flagUrl,
        podFlagUrl: podCountry?.flagUrl,
      };
    }
  );

  return heroSectionScheduleListWithCountry;
}
