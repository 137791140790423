import Image from "next/image";
import { useTranslation } from "next-i18next";

import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import TextButton from "@sellernote/_sds-v2/src/components/button/TextButton";
import FourChevronRightIcon from "@sellernote/_sds-v2/src/components/svgIcons/FourChevronRightIcon";

import { CustomerCardData } from "..";
import Styled from "./index.styles";

export default function CustomerCard({
  logoImage: { url, width, height, mobileWidth, mobileHeight },
  blogUrl,
  category,
  name,
  description,
  delay,
  isActivatedTarget,
}: CustomerCardData & {
  isActivatedTarget: boolean;
  delay: number;
}) {
  const { t } = useTranslation(["page-main"]);

  const { isMobile } = useCheckIsMobile();

  return (
    <Styled.customerCard
      className={`fade-up-animation ${isActivatedTarget ? "active" : ""}`}
      delay={delay}
    >
      <Styled.cardHeader>
        <Image
          src={url}
          width={isMobile ? mobileWidth : width}
          height={isMobile ? mobileHeight : height}
          alt={`customer-logo-${name}`}
          placeholder="blur"
          blurDataURL="/assets/images/landing/section-image-blur-data.png"
        />

        <TextButton
          theme="gray"
          label={t("page-main:자세히_보기")}
          fontSize={isMobile ? 12 : 14}
          iconInfo={{
            Icon: FourChevronRightIcon,
            position: "right",
          }}
          handleClick={() => {
            window.open(blogUrl, "_blank");
          }}
        />
      </Styled.cardHeader>

      <Styled.cardBody>
        <Styled.company>
          <span className="category">{t(`page-main:${category}`)}</span>

          <span className="name">{t(`page-main:${name}`)}</span>
        </Styled.company>

        <Styled.description>{description}</Styled.description>
      </Styled.cardBody>
    </Styled.customerCard>
  );
}
