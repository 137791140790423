import React from "react";
import Image from "next/image";
import { Trans } from "next-i18next";

import ScheduleSearchBarForMobile from "../../../../containers/searchBarForSchedule/ScheduleSearchBarForMobile";

import Styled from "./index.styles";

export default function TitleAndCtaForMobile() {
  return (
    <Styled.introBSection>
      <Styled.backgroundImageWrapper>
        <Image
          src="/assets/images/main/intro-video-mobile-test-type-b.png"
          alt="background-image"
          layout="fill"
          objectFit="cover"
          placeholder="blur"
          blurDataURL="/assets/images/main/intro-video-mobile-blur-data.png"
          priority
        />
      </Styled.backgroundImageWrapper>

      <Styled.textContainer>
        <Styled.title>
          <Trans
            i18nKey="page-main:투명한_일정과_mobileBr_/__합리적인_운임으로_mobileBr_/__물류를_쉽게"
            components={{
              mobileBr: <br />,
            }}
          />
        </Styled.title>

        <Styled.description>
          <Trans
            i18nKey="page-main:쉽다_포워딩_전문가가_직접_제공하는_mobileBr_/__디지털_포워딩_서비스를_지금_경험해보세요_"
            components={{
              mobileBr: <br />,
            }}
          />
        </Styled.description>
      </Styled.textContainer>

      <Styled.searchBarWrapper>
        <ScheduleSearchBarForMobile />
      </Styled.searchBarWrapper>
    </Styled.introBSection>
  );
}
