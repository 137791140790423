import { atom } from "jotai";

import { FORWARDING_SCHEDULE_ATOMS } from ".";

/** 선택한 스케줄의 출발지/도착지 정보 */
const SELECTED_PORT_INFO = atom((get) =>
  get(FORWARDING_SCHEDULE_ATOMS.BASE_SELECTED_PORT_INFO)
);

export default { SELECTED_PORT_INFO };
